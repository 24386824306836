import * as React from "react";
import Header from "./header";
import { Box } from "@chakra-ui/react";
import Seo from "./seo";

const Layout = ({ children, pageTitle }) => {
  return (
    <>
      <Seo title={pageTitle} />
      <Box m={"10px"} mx="auto" maxW="lg">
        <Header />
        <main>{children}</main>
      </Box>
    </>
  );
};
export default Layout;
