import * as React from "react";
import Layout from "../components/layout";

const AboutPage = () => {
  return (
    <Layout pageTitle={"About Me"}>
      <p>
        普段は主にバックエンド寄りのエンジニアをしています。
        <br />
        フロントにも関心があり、ブログ作成を始めてみました。
      </p>
    </Layout>
  );
};
export default AboutPage;
